<template>
  <MyAccountCheckAuthLoggedIn v-if="!isMobileOrTablet">
    <MyAccountProfileEdit />
  </MyAccountCheckAuthLoggedIn>
</template>

<script setup lang="ts">
const { url } = useDomainHost()
const { isMobileOrTablet } = useDevice()
const { t } = useI18n()
const { store } = useDomainState()

useServerSeoMeta(
  useOgMeta(
    url + '/my-account',
    t('profile'),
    store.value?.about,
    store.value?.thumb
  )
)

useHead({
  title: t('profile'),
  meta: [
    { hid: 'description', name: 'description', content: store.value?.about }
  ],
  link: [useSeoCanonical(url + '/my-account')],
  script: [
    useSeoBreadcrumb([
      useSeoBreadcrumbItem(1, t('home'), url),
      useSeoBreadcrumbItem(2, t('profile'), url + '/my-account')
    ])
  ]
})
</script>
